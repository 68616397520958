.headingBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    margin-bottom: 2rem;
}

.heading4, .parCount {
  margin: 0 !important;
}

.parCount {
    font-size: 14px;
    font-weight: 500;
    color: #858585;
}

.productsStateBox {
    margin-block: 2rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
}

.date {
    white-space: nowrap;
}
