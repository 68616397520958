

.container {
    min-height: 100px;
    background-color: var(--bg-color, red);
    border-radius: 14px;
    padding: 1rem;
    color: white;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-wrap: nowrap;
    flex: 1;
}

.container p {
    margin: 0;
}

.productsCount {
    font-size: 20px;
    font-weight: 600;
}

.percentage {
    font-size: 12px;
}