.sortAndSearchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
    width: 90%;
}

@media (max-width: 1000px) {
    .sortAndSearchContainer {
        flex-direction: column;
        width: 100;
    }
}

.sortSelect,
.searchInput {
    flex: 1;
    max-width: 50%;
    cursor: pointer;
    height: 3rem;
}

.sortSelect:focus {
    outline: none;
    border-color: #22e06c;
}

/* Stávající styly */
.checkboxContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

@media (max-width: 1100px) {
    .checkboxContainer {
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: start;
    }
}

.checkboxLabel {
    display: flex;
    align-items: center;
}

/* Pokud již nevyužíváte .sortBox, můžete jej odstranit nebo ponechat pro případné další úpravy */
.sortBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 1rem;
    margin-block: 1rem;
}

.headingBox {
    position: relative;
    width: 100%;
    text-align: center;
}

.usersBtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 200ms linear;
}

.usersBtn:hover {
    scale: 1.1;
}