.headline_and_btn_box {
    display: flex;
    justify-content: space-between;
}

.img-full-width {
    display: block;
    margin: 20px auto;
    width: 100%;
    cursor: zoom-in;
    transition: all 250ms linear;
}

.img-half-width {
    display: block;
    margin: 20px auto;
    width: 50%;
    cursor: zoom-in;
    transition: all 250ms linear;
}

/* Hover efekt jen pro desktop */
@media (min-width: 768px) {
    .img-full-width:hover,
    .img-half-width:hover {
        box-shadow: 0 0 5px 5px #99999999;
    }
}
