.dialogWrapper {
    position: relative;
    max-width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.infoBox {
    background-color: #f0f0f0;
    border-radius: 12px;
    padding: 12px;
}

.inputForNewPass {
    margin-bottom: 2rem;
}

.btnModalWrapper {
    display: flex;
    justify-content: space-between;
    gap: 5rem;
}

.btn {
    min-width: fit-content;
}

@media (max-width: 1280px) {
    .dialogWrapper {
        max-width: 40vw;
    }
    .inputForNewPass {
        margin-bottom: 1rem;
    }

    .btnModalWrapper {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    .btn {
        max-width: 300px ;
    }
}

@media (max-width: 800px) {
    .dialogWrapper {
        max-width: 70vw;
    }
}

@media (max-width: 500px) {
    .dialogWrapper {
        max-width: 90vw;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255); /* Poloprůhledné černé pozadí */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-radius: inherit; /* zachová zaoblení dialogWrapper */
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.3);
    border-top: 8px solid #009b45;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}