.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #123309;
    /* Lehce průhledné pozadí */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: #123309;
    padding: 20px;
    width: 100%;
    position: relative;
    overflow-y: auto;
    max-height: 100vh;
    /* Aby modal nebyl vyšší než viewport */
    color: white;
}

.closeButton {
    position: fixed;
    top: 5px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: white;
    transition: all 150ms linear;
}

.closeButton:hover {
    color: #9e9e9e;
}

@media (max-width: 700px) {
    .closeButton {
        right: 5px;
        bottom: 0;
        font-size: 24px;
        height: 60px;
        width: 10%;
        position: absolute;
    }
}