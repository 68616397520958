/* V CertifySection.module.css */

/* ... (mobilní styly zůstávají) ... */


/* === Responzivita pro větší obrazovky (Desktop - Grid) === */
/* Použijeme breakpoint 1064px, aby odpovídal originálu, pokud chcete */

  .certify3Container {
    display: flex !important;
    align-items: stretch !important;
    /* Stretch aby měly stejnou výšku */
    gap: 0 !important;
    /* Mezery řešíme paddingem */
    /* Můžeme zkusit aplikovat původní padding z .certify__3 */
    /* padding: 5px 30px 0 !important; */
    /* Padding na celém kontejneru */
    /* Nebo nechat padding na dětech (H3 a List) */
    position: relative;
  }

  .certifyHeading {
    /* flex-basis: 50% !important; */
    /* Můžeme nechat flex určit šířku */
    /* width: 50% !important; */
    flex-shrink: 0;
    /* Aby se nadpis nezmenšoval */
    max-width: 400px !important;
    /* Původní max šířka */
    margin-right: 25px !important;
    /* Původní mezera */
    text-align: left !important;
    padding: 1.5rem 0 1.5rem 1.5rem !important;
    /* Odsazení textu vlevo */
    box-sizing: border-box !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    position: relative;
    z-index: 1;
  }

  .certifyPartnersList {
    flex: 1 1 auto !important;
    /* Necháme seznam vyplnit zbytek místa */
    /* flex-basis: 50% !important; */
    /* Alternativa k flex: 1 */
    /* width: 50% !important; */
    box-sizing: border-box !important;
    position: relative;
    z-index: 1;

    /* --- GRID Nastavení --- */
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    /* 4 sloupce */
    grid-template-rows: repeat(2, auto) !important;
    /* 2 řádky */
    gap: 1rem 1.5rem !important;
    /* Mezery: 1rem vertikálně, 1.5rem horizontálně */
    align-items: center !important;
    align-content: center !important;

    /* === ÚPRAVA PADDINGU === */
    /* Odsazení uvnitř seznamu log od okrajů jeho prostoru */
    /* Zkuste hodnoty mezi 0.5rem a 1.5rem, aby se vešlo do SVG */
    padding: 1rem 1.5rem !important;
    /* Horní/dolní 1rem, Pravý/Levý 1.5rem */
  }

  .certifyPartnersList li {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* Můžeme přidat minimální výšku buňky, pokud jsou loga moc nízká */
    /* min-height: 60px !important; */
  }

  .certifyPartnersList img {
    max-width: 100% !important;
    width: auto !important;
    height: auto !important;
    object-fit: contain !important;

    /* === ÚPRAVA MAX-HEIGHT === */
    /* Zvětšíme povolenou výšku loga */
    max-height: 55px !important;
    /* Zkusíme 55px */
  }


/* Případně specifický breakpoint pro největší obrazovky dle originálu */
@media (min-width: 1320px) {
  .certify3Container {
    /* padding: 0 50px !important; */
    /* Větší padding pro širší obrazovky */
  }

  .certifyPartnersList {
    padding: 1rem 2rem !important;
    /* Větší padding pro širší obrazovky */
    gap: 1rem 2rem !important;
    /* Větší mezery pro širší obrazovky */
  }

  .certifyPartnersList img {
    max-height: 60px !important;
    /* Ještě větší loga na nejširší */
  }
}