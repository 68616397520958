// hlavní navigace
.app-nav-header {
  display: flex;
  align-items: center;
  flex-grow: 0;

  width: auto;

  @media (max-width: 399px) {
    margin-right: -14px;
  }

  ul,
  li {
    margin: 0;
    padding: 0;

    list-style-type: none;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  li a {
    display: block;

    margin-right: 8px;

    color: $color_white;

    @media (min-width: 400px) {
      margin-right: 20px;
    }

    @media (min-width: $mqmd) {
      margin-right: 0;
      padding-left: 20px;
    }

    @media (min-width: $mqlg) {
      padding-left: 40px;
    }

    @media (max-width: $mqmd - 1px) {
      overflow: hidden;

      width: 24px;
      height: 24px;
    }

    &:hover,
    &:focus {
      color: $color_main;

      .icon {
        color: $color_main;
      }
    }
  }

  strong {
    font-weight: 500;
    white-space: nowrap;
  }

  .icon {
    color: $color_white;

    transition: all 0.4s;
  }
}
