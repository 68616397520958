.codeBox {
    position: relative;
    outline: 1px solid black;
    pointer-events: none;
    border-radius: 4px !important;
    border: none;
    background-color: white;
}

.copyIcon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 200ms linear;
    pointer-events: all;
}

.copyIcon:hover {
    scale: 1.1;
}