.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.svg-container-small {
  display: flex;
  justify-content: center;
  align-items: center;
}

#svg2534 {
  width: 200px;
  height: auto;

  overflow: visible;
}

#svg2534-small {
  width: 100px;
  height: auto;

  overflow: visible;
}

#g4042 {
  transform: translate(21px, 51px);
}

#g4042-small {
  transform: translate(50%, 50%);
}


@keyframes pulseA {
  0% {
    /* transform: scaleY(1); */
  }

  25% {
    /* transform: scaleY(1.2) scaleX(1.15) rotate(3deg); */
    d: path("M -7.9,10.1 C -4.067757,13.9384 3.8235498,14.227127 7.5168471,10.256015 16.579679,0.51146492 9.7909549,-8.0296362 7.7084649,-10.124982 0.97481498,-16.9002 -4.49614,-8.481572 -7.9359935,-30.118499 -14.289046,-22.619562 -15.951485,2.2342996 -7.89999,10.1 Z")
  }

  50% {
    /* transform: scaleY(1.1) scaleX(1.1) rotate(0deg); */
    d: path("M -7.9,10.1 C -4.067757,13.9384 3.65143,14.059793 7.5168471,10.256015 17.849406,0.08822256 7.9989987,-9.4394193 7.369871,-12.325842 5.3765355,-21.471217 16.327384,-17.45431 11.363858,-31.811468 6.1958846,-18.810381 -23.061957,-7.8388685 -7.89999,10.1 Z")
  }

  60% {
    /* transform: scaleY(1.2) scaleX(1.15) rotate(-3deg); */
    d: path("M -7.9,10.1 C -4.067757,13.9384 3.65143,14.059793 7.5168471,10.256015 17.849406,0.08822256 8.9301319,-7.6618014 8.3010042,-10.548224 6.3076687,-19.693599 15.142305,-24.141539 10.178779,-38.498697 8.8199872,-22.788859 -23.061957,-7.8388685 -7.89999,10.1 Z")
  }

  100% {
    /* transform: scaleY(1); */
  }
}

/* Apply the animation to the elements */
#path4942 {
  animation: pulseA 2s infinite linear;
  transform-origin: 0 13px;
}

@keyframes pulseB {
  0% {
    /* transform: scaleY(1); */

  }

  25% {
    /* transform: scaleY(1.2) scaleX(1.1) rotate(-6deg); */
  }

  50% {
    transform: scaleY(1.1) scaleX(1.1) rotate(0deg);
    d: path("M 4.5,11.45 C 2.5838785,13.3692 -1.709548,13.666975 -4.225819,11.57131 -7.145159,8.211465 -3.976329,5.4204419 -2.362564,0.34741686 -0.87521698,-5.1772931 -5.1501101,-4.2470377 -1.1054277,-9.8588157 -0.06617425,-3.6451512 11.264482,3.782555 4.5,11.45 Z")
  }

  75% {
    /* transform: scaleY(1.2) scaleX(1.1) rotate(8deg); */
  }

  100% {
    /* transform: scaleY(1); */
  }
}

/* Apply the animation to the elements */
#path4942-7 {
  animation: pulseB 1s infinite linear;
  transform-origin: 0 13.3px;
}