.contact_us_modal_layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 1001;
}

.send_message_plugin {
  width: 50px;
  height: 50px;
  border-radius: 100vh;
  background-color: #22e06c;
  z-index: 1000;
  position: fixed;
  bottom: 1.2rem;
  right: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  transition: all 200ms linear;
  outline: none;
}

h3 {
  margin-top: 10px;
}

h4 {
  margin-top: 16px;
}

.send_message_plugin:focus {
  outline: none;
}

.send_message_plugin {
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) and (pointer: fine) {
  .send_message_plugin:hover {
    box-shadow: 0 0 0 5px #123309, 0 0 0 10px #22e06c;
  }

  .send_message_plugin:active {
    box-shadow: 0 0 0 7px #123309, 0 0 0 12px #22e06c;
    transform: scale(1.05);
    transition: box-shadow 100ms ease, transform 100ms ease;
  }
}

@media (hover: none) and (pointer: coarse) {
  .send_message_plugin:active {
    box-shadow: 0 0 0 10px #22e06c;
  }
}

[data-btn-type="big"] {
  z-index: 1000;
  position: fixed;
  bottom: 1.2rem;
  right: 1.2rem;
}

.contact_us_modal {
  width: 100%;
  max-width: 1100px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  border-radius: 24px;
  padding: 32px;
}

@media (max-width: 1064px) {
  .contact_us_modal {
    width: 90vw;
  }
}

.contact_us_wrapper {
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  max-height: 900px;
  overflow-y: auto;
}

.contact_us_fields {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
}

@media (max-width: 1000px) {
  .contact_us_fields {
    flex-direction: column;
    gap: 0;
  }

  .contact_me_btn {
    width: 100%;
  }

  .btn_box {
    flex-direction: column;
    gap: 1rem;
  }
}

.input_wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.contactUsPhone,
.contactUsEmail {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  box-sizing: border-box;
}

.contactUsTextarea {
  width: 100%;
  height: 100px;
  padding: 8px;
  font-size: 1rem;
  box-sizing: border-box;
  resize: none;
}

.btn_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 1rem;
}

.greenDot,
.redDot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100vh;
  margin-right: 6px;
}

.greenDot {
  background-color: green;
}

.redDot {
  background-color: red;
}

.par,
.parStatus {
  margin: 0;
}

@media (max-width: 725px) {
  .contactUsPhone,
  .contactUsEmail {
    width: 100%;
  }
}

.contactUsQuestions {
  max-width: 100%;
  max-height: 500px;
  overflow-y: auto;
  /* flex: 4; */
}
.contactUsForm {
  max-width: 100%;
  max-height: 500px;
  overflow-y: auto;
  /* flex: 6; */
}

.questionPair {
  margin: 1rem 1rem 1rem 0;
  border: 2px solid lightgray;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 12px;
  transition: all 200ms linear;
}

.questionPair:hover {
  background-color: lightgray;
}

.questionPair:focus {
  border-color: #22e06c;
}

.separator {
  margin-inline: 2rem;
}

textarea:focus {
  outline: none;
  border-color: #22e06c;
}

dl {
  overflow: hidden;
  text-overflow: ellipsis;
}

dt {
  font-weight: 600;
}

.contactUsQuestions dl dd {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.parStatus {
  margin: 0 !important;
}

@media (max-width: 900px) {
  .contact_us_wrapper {
    flex-direction: column-reverse;
    justify-content: end;
    max-height: 80vh;
  }

  .separator {
    margin-block: 2rem;
  }
}

section {
  flex: 1;
}

/* .contact_us_modal_layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 1001;
}

.send_message_plugin {
  width: 50px;
  height: 50px;
  border-radius: 100vh;
  background-color: #22e06c;
  z-index: 1000;
  position: fixed;
  bottom: 1.2rem;
  right: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  transition: all 200ms linear;
  outline: none;
}

.send_message_plugin:focus {
  outline: none;
}

.send_message_plugin {
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) and (pointer: fine) {
  .send_message_plugin:hover {
    box-shadow: 0 0 0 5px #123309, 0 0 0 10px #22e06c;
  }

  .send_message_plugin:active {
    box-shadow: 0 0 0 7px #123309, 0 0 0 12px #22e06c;
    transform: scale(1.05);
    transition: box-shadow 100ms ease, transform 100ms ease;
  }
}

@media (hover: none) and (pointer: coarse) {
  .send_message_plugin:active {
    box-shadow: 0 0 0 10px #22e06c;
  }
}

.contact_us_modal {
  width: fit-content;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  border-radius: 24px;
  padding: 32px;
}

@media (max-width: 1064px) {
  .contact_us_modal {
    width: 90vw;
  }
}

.contact_us_wrapper {
  background-color: #f3f3f3;
  width: fit-content;
  height: 100%;
  border-radius: 12px;
  padding: 16px;
}

.btn_box {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}

.close_btn {
  z-index: 1002;
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
  font-size: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100vh;
  transition: all 200ms linear;
}

.close_btn:hover {
  font-size: 35px;
  color: rgba(255, 255, 255, 0.5);
}

.contactUsPhone {
  width: 50%;
}

@media (max-width: 725px) {
  .contactUsPhone {
    width: 100%;
  }
} */
