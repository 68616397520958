/* LoadingModal.module.css */

.moduleLoadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999999999;
}


.moduleLoading {
  /* background-color: white; */
  /* padding: 20px; */
  /* border-radius: 8px; */
  /* text-align: center; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
  padding-bottom: 10rem;
}
