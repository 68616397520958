.dataplus_anchor {
  font-weight: 600;
  text-decoration: underline;
}

.pno_budget_set_box {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.header__switcher__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

@media (max-width: 850px) {
  .header__switcher__box {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
}

.codeBox {
  position: relative;
  outline: none;
  pointer-events: none;
}

.copyIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 200ms linear;
  pointer-events: all;
}

.copyIcon:hover {
  scale: 1.1;
}

.dpTutor {
  cursor: pointer;
  transition: all 200ms linear;
  position: relative;
}

.dpTutor::before {
  content: "";
  position: absolute;
  top: -10px;
  right: -20px;
  bottom: -10px;
  left: -20px;
  border-radius: 100vh;
}

.dpTutor:hover {
  scale: 1.1;
}

.billingBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.billingBox input,
.billingBox select {
  height: 50px;
}

.userList {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 0 !important;
  gap: 1rem;
}

.userItem {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.deleteBtn {
  cursor: pointer;
  scale: 0.8;
  transition: all 200ms linear;
}

.deleteBtn:hover {
  scale: 1;
  fill: #d30000;
}
