.container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
}

.modal_box {
    width: 50vw;
    height: 90vh;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
    padding: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal_box p, h2 {
    margin: 0;
}

/* Pevná hlavička modalBox */
.header {
    flex: 0 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem; /* Přidána mezera mezi userInfo a selectRow */
}

/* Info o uživateli */
.userInfo {
    display: flex;
    flex-direction: column;
    /* Omezíme šířku, aby select měl dostatek místa */
    flex-grow: 1; /* Umožní userInfo roztáhnout se */
    min-width: 0; /* Důležité pro flexbox a zalamování textu */
}

.userInfo p {
    margin: 0;
    font-size: 1rem;
    color: #333;
}

/* ===== ZAČÁTEK PŘIDANÝCH/UPRAVENÝCH STYLŮ ===== */

/* Kontejner pro titulek a ikonu kopírování */
.titleContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Mezera mezi ikonou a textem */
    /* Omezíme šířku, aby se titulek zalamoval a ikona zůstala viditelná */
    max-width: 100%; /* Nebo konkrétní hodnota, pokud je potřeba */
    overflow: hidden; /* Skryje přebytečný obsah kontejneru */
}

/* Nadpis modalBox - upravujeme max-width */
.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Max-width se nyní aplikuje na celý .titleContainer nebo je řízen flexboxem */
    /* max-width: 600px; -> Odstraněno nebo upraveno, flex item se postará */
    flex-shrink: 1; /* Umožní titulku zmenšit se, pokud není místo */
    min-width: 0; /* Důležité pro ellipsis ve flex kontejneru */
    margin: 0 !important; /* Reset marginu pro h2 */
}

/* Styly zkopírované z AppContent.module.css */
.copyBox {
    position: relative;
    flex-shrink: 0; /* Ikona se nebude zmenšovat */
}

.copyIcon {
    cursor: pointer;
    font-size: 1.4rem; /* Můžete upravit velikost ikony */
    transition: all 200ms linear;
    display: block; /* Zajistí správné zarovnání */
    color: #555; /* Můžete nastavit barvu ikony */
}

.copyIcon:hover {
    scale: 1.1;
    color: #000; /* Barva při najetí */
}

@keyframes shake {
    0% { transform: translateX(0); }
    10% { transform: translateX(-2px) rotate(-5deg); }
    20% { transform: translateX(2px) rotate(5deg); }
    30% { transform: translateX(-2px) rotate(-5deg); }
    40% { transform: translateX(2px) rotate(5deg); }
    50% { transform: translateX(-2px) rotate(-5deg); }
    60% { transform: translateX(2px) rotate(5deg); }
    70% { transform: translateX(-2px) rotate(-5deg); }
    80% { transform: translateX(2px) rotate(5deg); }
    90% { transform: translateX(-2px) rotate(-5deg); }
    100% { transform: translateX(0); }
}

.shake {
    animation: shake 1s ease-in-out;
    color: green; /* Barva potvrzovací ikony */
}

/* ===== KONEC PŘIDANÝCH/UPRAVENÝCH STYLŮ ===== */


/* Select pro změnu stavu */
.selectRow {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Zmenšená mezera */
    flex-shrink: 0; /* Zabrání zmenšování selectu */
}

.selectInput {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin: 0;
}

/* Řádek s nadpisem Obchody a vyhledávacím polem */
.shopHeader {
    flex: 0 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 0.5rem 0;
}

.shopHeader h3 {
    margin: 0;
    font-size: 1.4rem;
}

.shopSearch {
    max-width: 400px;
    padding: 0.4rem 0.8rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 0.9rem;
    margin: 0;
}

/* TextBox s obchody */
.textBox {
    flex: 1 1 auto;
    border-radius: 16px;
    background-color: #f0f0f0;
    padding: 1rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    scrollbar-width: none; /* Firefox */
}
.textBox::-webkit-scrollbar { /* Chrome, Safari, Edge */
    display: none;
}


/* Detail obchodu */
.shopDetail {
    margin-bottom: 1rem;
}

/* Adresa */
.address {
    /* margin-left: 1rem; */
}

/* Spodní část – tlačítka */
.btn_box {
    flex: 0 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem; /* Mezery mezi tlačítky */
}

/* Tlačítka */
.btn {
    background-color: #dafd82;
    border: 2px solid #dafd82;
    border-radius: 24px;
    color: #000;
    cursor: pointer;
    font: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    padding: 12px;
    text-align: center;
    transition: all 0.4s;
    flex-grow: 1; /* Tlačítka se rovnoměrně roztáhnou */
    max-width: 30%; /* Omezení maximální šířky tlačítek */
}

.btn:hover,
.btn:focus {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.delete {
    background-color: #ffc4c4;
    border: 2px solid #ffc4c4;
}
.delete:hover,
.delete:focus {
    background-color: #d63031; /* Tmavší červená při hoveru */
    border-color: #d63031;
    color: #fff;
}


/* Hodnota uživatele */
.userValue {
    font-size: 1rem;
    color: #555;
    margin-right: 1rem;
}

.nothing {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    margin: auto; /* Vycentrování vertikálně i horizontálně v textBoxu */
    color: #888;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

/* --- Media Queries pro menší obrazovky --- */
@media (max-width: 1200px) {
    .modal_box {
        width: 70vw;
    }
    .title {
        /* Můžeme zde více omezit šířku, pokud je potřeba */
    }
}

@media (max-width: 768px) {
    .modal_box {
        width: 90vw;
        height: 95vh;
        padding: 0.8rem;
    }
    .header {
        flex-direction: column; /* Dáme pod sebe na malých obrazovkách */
        align-items: flex-start;
        flex: 0 0 auto; /* Výška se přizpůsobí obsahu */
        margin-bottom: 0.5rem;
    }
    .userInfo {
        width: 100%; /* Zabere celou šířku */
        margin-bottom: 0.5rem;
    }
    .selectRow {
        width: 100%;
        justify-content: space-between; /* Rozložení labelu a selectu */
    }
    .shopHeader {
        flex-direction: column;
        align-items: stretch;
        flex: 0 0 auto; /* Výška se přizpůsobí obsahu */
        gap: 0.5rem;
        margin-top: 0.5rem;
    }
    .shopSearch {
        max-width: none; /* Zabere celou šířku */
        width: 100%;
        box-sizing: border-box;
    }
    .btn_box {
        flex: 0 0 auto; /* Výška se přizpůsobí obsahu */
        gap: 0.5rem;
    }
    .btn {
       font-size: 0.9rem;
       padding: 10px;
       max-width: 32%; /* Mírné upravení pro menší obrazovky */
    }
}