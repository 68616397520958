.learnBox {
    display: flex;
    justify-content: space-between;
    pointer-events: none;
}

@media (max-width: 500px) {
    .learnBox {
        flex-direction: column;
        align-items: start;
        gap: 1rem;
    }
}

.heading {}

.iconBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .4rem;
}

.iconText {
    font-weight: bold;
    /* color: #22E06C; */
}

.gear {
    /* color: #22E06C; */
    width: 1.6rem;
    animation: rotateGear 5s linear infinite;
}

@keyframes rotateGear {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.toggleBtnBox {
    cursor: pointer;
    transition: all 200ms linear;
}

.toggleBtnBox:hover {
    box-shadow: 0 0 10px #99999999 !important;
}