/* FeedTutor.module.css */

/* Modal container – zabírá celou obrazovku */
.container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal box */
.modal_box {
    max-width: 80vw;
    max-height: 90vh;
    background-color: #fff;
    position: relative;
    border-radius: 24px;
    padding: 1.5rem;
    overflow-y: auto;
}

@media (max-width: 640px) {
    .modal_box {
        width: 90vw;
    }
}

/* Kontejner pro obsah modalu */
.textBox {
    border-radius: 16px;
    text-align: left;
    padding: 1rem;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: scroll;
    scrollbar-width: none;
}

/* Zavírací tlačítko */
.close_btn {
    z-index: 1002;
    position: absolute;
    top: 20px;
    right: 40px;
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 200ms linear;
    background: transparent;
    border: none;
}

@media (max-width: 500px) {
    .close_btn {
        right: 10px;
    }
}

.close_btn:hover {
    font-size: 35px;
    color: rgba(255, 255, 255, 0.5);
}

/* FAQ položky pro FeedTutor */
.feedTutorItem {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.feedTutorItem:last-child {
    border-bottom: none;
}

.feedTutorItem:hover {
    /* případně změna barvy pozadí při hover */
    /* background-color: #f5f5f5; */
}

/* Nadpis otázky v FAQ */
.feedTutorQuestion {
    margin: 0;
    display: flex;
    align-items: center;
}

/* Ikona loga – případně s odsazením */
.logoIcon {
    margin-right: 10px;
}

/* Odpověď s návody */
.feedTutorAnswer {
    overflow: hidden;
    transition: max-height 0.3s ease;
    margin-top: 10px;
}

.feedTutorAnswer ol {
    margin: 0;
    padding-left: 20px;
}

.feedTutorAnswer li {
    margin-bottom: 8px;
    font-size: 14px;
    color: #555;
}

/* Odkazy uvnitř FAQ */
.link {
    color: #007bff;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}