.find_ico_btn {
    position: absolute;
    top: 55%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 24px;
    margin: 0;
    cursor: pointer;
    transition: transform 200ms linear;
}

.find_ico_btn.pulsing {
    animation: pulse 1s ease-in-out 3;
    /* 3 opakování */
}

@keyframes pulse {
    0% {
        transform: translateY(-50%) scale(1);
    }

    50% {
        transform: translateY(-50%) scale(1.1);
    }

    100% {
        transform: translateY(-50%) scale(1);
    }
}

.ico_company_tooltip {
    background-color: #fff;
    border: 1px solid #555;
    border-radius: 4px;
    color: #000;
    font: inherit;
    font-size: 1rem;
    line-height: 1.5;
    max-width: 100%;
    padding: 12px;
    width: 100%;
    position: absolute;
    cursor: pointer;
    margin-top: 2px;
    display: block;
}

.ico_company_tooltip:hover, .ico_company_tooltip:hover * {
    outline: none;
    border-color: #22e06c;
}

.ico_tooltip_close_btn {
    position: absolute;
    border-radius: 5px;
    text-align: center;
    font-size: 30px;
    top: 107%;
    right: 10px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    transition: all 200ms linear;
}

.ico_tooltip_close_btn:hover {
    scale: 1.1;
}