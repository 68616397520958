.campaign__status {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 12px;
    font-size: .875rem;
    height: 24px;
    margin-top: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

@media (min-width:576px) {
    .campaign__status {
        width: 150px;
    }
}

.campaign__status em {
    position: absolute;
    right: 10px;
    transform: translateX(-50%);
    z-index: 2;
    font-style: normal;
    white-space: nowrap;
    color: #000;
}

.progress__fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 12px;
    width: calc(var(--value) / 30 * 100%);
    transition: width 0.3s ease, background-color 0.3s ease;
    z-index: 1;
}
