.close_btn {
  z-index: 999999999999;
  position: absolute;
  top: 20px;
  right: 30px;
  width: 30px;
  height: 30px;
  border-radius: 100vh;
  transition: all 200ms linear;
  border: transparent;
}

.close_btn:hover {
  scale: 1.1;
}
