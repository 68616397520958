.contact_list {
    list-style-type: none;
}

.contact_list li::before {
    content: url("../img/list.svg");
    margin-right: 5px;
    vertical-align: middle;
}

.signing_process_list,
.provide_services_list,
.payment_terms_list,
.service_and_support_list,
.rights_and_obligations_list,
.intellectual_property_list,
.contract_duration_and_termination_list,
.responsibility_list,
.how_we_protect_information_list,
.final_provisions_list,
.introductory_provisions_list,
.personal_data_list,
.rights_and_obligations_contracting_parties_list,
.duration_processing_agreement_list,
.personal_responsibility_list,
.personal_final_provisions_list {
    list-style: none;
    counter-reset: section;
    text-align: justify;
}

.signing_process_list>li,
.provide_services_list>li,
.payment_terms_list>li,
.service_and_support_list>li,
.rights_and_obligations_list>li,
.intellectual_property_list>li,
.contract_duration_and_termination_list>li,
.responsibility_list>li,
.how_we_protect_information_list>li,
.final_provisions_list>li,
.introductory_provisions_list>li,
.personal_data_list>li,
.rights_and_obligations_contracting_parties_list>li,
.duration_processing_agreement_list>li,
.personal_responsibility_list>li,
.personal_final_provisions_list>li {
    counter-increment: section;
    margin-block: 1.5rem;
}

.signing_process_list>li::before {
    content: "1." counter(section) " ";
    margin-right: 8px;
}

.provide_services_list>li::before {
    content: "2." counter(section) " ";
    margin-right: 8px;
}

.payment_terms_list>li::before {
    content: "3." counter(section) " ";
    margin-right: 8px;
}

.service_and_support_list>li::before {
    content: "4." counter(section) " ";
    margin-right: 8px;
}

.rights_and_obligations_list>li::before {
    content: "5." counter(section) " ";
    margin-right: 8px;
}

.intellectual_property_list>li::before {
    content: "6." counter(section) " ";
    margin-right: 8px;
}

.contract_duration_and_termination_list>li::before {
    content: "7." counter(section) " ";
    margin-right: 8px;
}

.responsibility_list>li::before {
    content: "8." counter(section) " ";
    margin-right: 8px;
}

.how_we_protect_information_list>li::before {
    content: "9." counter(section) " ";
    margin-right: 8px;
}

.final_provisions_list>li::before {
    content: "10." counter(section) " ";
    margin-right: 8px;
}

.introductory_provisions_list>li::before {
    content: "1." counter(section) " ";
    margin-right: 8px;
}

.personal_data_list>li::before {
    content: "2." counter(section) " ";
    margin-right: 8px;
}

.rights_and_obligations_contracting_parties_list>li::before {
    content: "3." counter(section) " ";
    margin-right: 8px;
}

.duration_processing_agreement_list>li::before {
    content: "4." counter(section) " ";
    margin-right: 8px;
}

.personal_responsibility_list>li::before {
    content: "5." counter(section) " ";
    margin-right: 8px;
}

.personal_final_provisions_list>li::before {
    content: "6." counter(section) " ";
    margin-right: 8px;
}

.subpoints {
    list-style-type: none;
    padding-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .subpoint_letter {
    font-weight: bold;
    margin-right: 5px;
  }