.helpSection {
    /* Styly pro sekci s informacemi */
    margin-top: 20px;
}

.headingBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

@media (max-width: 1100px) {
    .headingBox {
        flex-direction: column;
        align-items: start;
        gap: 1rem;
    }
}

.mainHeading {
    margin: 0 !important;
}

.billingTable {
    width: 100%;
    border-collapse: collapse;
}

.tableHeader {
    border-bottom: 1px solid #ccc;
    padding: 8px;
}

.tableHeaderLeft {
    text-align: left;
}

.tableHeaderRight {
    text-align: right;
}

.tableCell {
    padding: 8px;
}

.tableCellRight {
    padding: 8px;
    text-align: right;
}

.tableRowTotal {
    font-weight: bold;
    border-top: 1px solid #ccc;
}

.message {
    margin-top: 16px;
}

.exportBtn {
    margin-bottom: 10px;
}