.anchor_to_credit {
    cursor: pointer;
    color: #555;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-color: #555;
    transition: all 150ms linear;
}

.anchor_to_credit:hover {
    text-decoration-color: transparent;
}

.stats_icon {
    transition: all 150ms linear;
    cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
    .stats_icon:hover {
        box-shadow: 0 0 0 5px var(--primary-hover-color), 0 0 0 10px var(--secondary-hover-color);
    }

    .stats_icon:active {
        box-shadow: 0 0 0 7px var(--primary-hover-color), 0 0 0 12px var(--secondary-hover-color);
        transform: scale(1.05);
        transition: box-shadow 100ms ease, transform 100ms ease;
    }
}

@media (hover: none) and (pointer: coarse) {
    .stats_icon:active {
        box-shadow: 0 0 0 5px var(--primary-hover-color), 0 0 0 10px var(--secondary-hover-color);
    }
}