.infoBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.copyBox {
    position: relative;
}

.copyIcon {
    cursor: pointer;
    font-size: 1.4rem;
    transition: all 200ms linear;
}

.copyIcon:hover {
    scale: 1.1;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(-2px) rotate(-5deg);
    }

    20% {
        transform: translateX(2px) rotate(5deg);
    }

    30% {
        transform: translateX(-2px) rotate(-5deg);
    }

    40% {
        transform: translateX(2px) rotate(5deg);
    }

    50% {
        transform: translateX(-2px) rotate(-5deg);
    }

    60% {
        transform: translateX(2px) rotate(5deg);
    }

    70% {
        transform: translateX(-2px) rotate(-5deg);
    }

    80% {
        transform: translateX(2px) rotate(5deg);
    }

    90% {
        transform: translateX(-2px) rotate(-5deg);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 1s ease-in-out;
}