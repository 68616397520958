.waiting,
.phone-verification-modal {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

#phone-verification-code {
  margin-block: 1rem;
}

.email_for_share_box {
  padding-block: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.input_box {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.email_for_share {
  margin: 0;
}

.plusIcon,
.minusIcon {
  padding: 12px;
  background-color: transparent;
  border-radius: 4px;
  transition: all 200ms linear;
}

.plusIcon:hover,
.minusIcon:hover {
  scale: 1.2;
}

.removeAccountBtn {
  margin-top: 2rem;
  margin-left: auto;
  display: block;
  margin-bottom: 0 !important;
  border: none;
}
