.container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9999999;
    top: 0;
}

.modal_box {
    width: 500px;
    max-height: 90vh;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 24px;
    transform: translate(-50%, -50%);
    padding: 1rem;
}

@media (max-width: 640px) {
    .modal_box {
        width: 90vw;
    }
}

.textBox {
    border-radius: 16px;
    text-align: left;
    padding: 1rem;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: scroll;
    scrollbar-width: none;
    /* gap: 1rem; */
}

.close_btn {
    z-index: 1002;
    position: absolute;
    top: 20px;
    right: 40px;
    color: white;
    cursor: pointer;
    font-size: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vh;
    transition: all 200ms linear;
}

.close_btn:hover {
    font-size: 35px;
    color: rgba(255, 255, 255, 0.5);
}

.text {
    border-radius: 12px;
    margin: 10px 0 20px 0;
}

.btn_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

@media (max-width: 400px) {
    .btn_box {
        flex-direction: column;
        gap: 1rem;
    }

    .btn {
        width: 100%;
    }
}

.btn {
    background-color: #dafd82;
    border: 2px solid #dafd82;
    border-radius: 24px;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    -webkit-transition: all .4s;
    transition: all .4s;
}

.ai_btn:focus,
.ai_btn:hover,
.btn:focus,
.btn:hover,
a.btn:focus,
a.btn:hover {
    background-color: #000;
    border-color: #000;
    color: #fff
}

.delete {
    background-color: #FFC4C4;
    border: 2px solid #FFC4C4
}