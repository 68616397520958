.pno_budget_set_box {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
}

@media (min-width: 1063px) {
    .register_ranger {
        position: absolute;
        width: 80%;
        bottom: 20px;
    }
}