.not_found_container {
    padding-top: 84px;
    display: flex;
    min-height: 60vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    max-width: fit-content;
    justify-self: center;
}

.heading {
    color: #DBFD83;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.text {
    font-size: 18px;
    margin-bottom: 0 !important;
}

.emailInput {
    /* max-width: 50%; */
}


.btnBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 350px) {
    .btnBox {
        flex-direction: column-reverse;
        justify-content: center;
        gap: 1rem;
    }

    .not_found_container {
        text-align: center;
    }

    .box {
        align-items: center !important;

    }
}

/* .btn {
    align-self: end;
} */

.textArea:focus {
    outline: none;
    border-color: #22e06c;
}