.container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9999999;
    top: 0;
}

.modal_box {
    width: 40vw;
    height: fit-content;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 24px;
    transform: translate(-50%, -50%);
    padding: 1rem;
}

@media (max-width: 1000px) {
    .modal_box {
        width: 90vw;
    }
}


.textBox {
    border-radius: 16px;
    text-align: justify;
    padding: 1rem;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.close_btn {
    z-index: 1002;
    position: absolute;
    top: 20px;
    right: 40px;
    color: white;
    cursor: pointer;
    font-size: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vh;
    transition: all 200ms linear;
}

.close_btn:hover {
    font-size: 35px;
    color: rgba(255, 255, 255, 0.5);
}

/* .info_text_heading {
    margin: 0 0 1rem 0;
} */

.text {
    border-radius: 12px;
    margin: 0;
}

.btn_box {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 1rem;
}

.btn {
    width: fit-content;
    margin: 0;
}