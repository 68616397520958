.app-nav-header {
  display: flex;
  align-items: center;
  flex-grow: 0;
  width: auto;
}
@media (max-width: 399px) {
  .app-nav-header {
    margin-right: -14px;
  }
}
.app-nav-header ul,
.app-nav-header li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.app-nav-header ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.app-nav-header li a {
  display: block;
  margin-right: 8px;
  color: #fff;
}
@media (min-width: 400px) {
  .app-nav-header li a {
    margin-right: 20px;
  }
}
@media (min-width: 1064px) {
  .app-nav-header li a {
    margin-right: 0;
    padding-left: 20px;
  }
}
@media (min-width: 1320px) {
  .app-nav-header li a {
    padding-left: 40px;
  }
}
@media (max-width: 1063px) {
  .app-nav-header li a {
    overflow: hidden;
    width: 24px;
    height: 24px;
  }
}
.app-nav-header li a:hover, .app-nav-header li a:focus {
  color: #22e06c;
}
.app-nav-header li a:hover .icon, .app-nav-header li a:focus .icon {
  color: #22e06c;
}
.app-nav-header strong {
  font-weight: 500;
  white-space: nowrap;
}
.app-nav-header .icon {
  color: #fff;
  transition: all 0.4s;
}

.app-nav {
  margin-bottom: 9px;
  padding: 12px 0;
  border-radius: 24px;
  background-color: #22e06c;
}
@media (min-width: 768px) {
  .app-nav {
    align-self: flex-start;
    flex: 0 0 auto;
    width: 293px;
    margin-right: 16px;
  }
}
@media (min-width: 1064px) and (min-height: 600px) {
  .app-nav {
    position: sticky;
    top: 84px;
  }
}
.app-nav ul,
.app-nav li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.app-nav a {
  position: relative;
  display: block;
  padding: 12px 24px 12px 64px;
  color: #000;
  line-height: 1.5;
  border-radius: 12px;
}
.app-nav a:hover, .app-nav a:focus, .app-nav a.is-active {
  background-color: #fff;
}
.app-nav a:hover .icon, .app-nav a:focus .icon, .app-nav a.is-active .icon {
  color: #22e06c;
}
.app-nav a.is-active {
  font-weight: 700;
  background-color: #f3f3f3;
}
@media (min-width: 768px) {
  .app-nav a.is-active:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -16px;
    display: block;
    width: 16px;
    height: 28px;
    background: url("../img/connector-nav.svg");
    transform: translateY(-50%);
  }
}
.app-nav .icon {
  position: absolute;
  top: 12px;
  left: 24px;
  color: #fff;
}

.app-content {
  flex: 1 1 auto;
  width: 100%;
  padding: 16px;
  border-radius: 24px;
  background-color: #f3f3f3;
}
@media (min-width: 1064px) {
  .app-content {
    padding: 32px;
  }
}
.app-content h1,
.app-content h2,
.app-content h3 {
  margin-bottom: 24px;
  line-height: 1.3;
}
.app-content h1 {
  font-size: 28px;
}
@media (min-width: 1064px) {
  .app-content h1 {
    font-size: 32px;
  }
}
.app-content h2 {
  font-size: 20px;
}
@media (min-width: 1320px) {
  .app-content h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
  }
}
.app-content h2 small {
  display: block;
  margin-top: 5px;
  padding-left: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background: url("../img/tooltip.svg") left 2px no-repeat;
  background-size: 16px auto;
  opacity: 0.7;
}
@media (min-width: 1320px) {
  .app-content h2 small {
    margin-top: 0;
  }
}
.app-content h4 {
  margin-top: 22px;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 600;
}
.app-content p {
  margin-bottom: 24px;
  word-break: break-word;
}
.app-content .btn {
  font-weight: 700;
}
@media (min-width: 1064px) {
  .app-content .box {
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 16px;
  }
}
.app-content > *:last-child, .app-content form > *:last-child {
  margin-bottom: 0;
}

.app-content--dashboard h2 {
  margin-bottom: 12px;
}

@media (min-width: 1064px) {
  .app-content__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 1064px) {
  .app-content__row > .box, .app-content__row > .info, .app-content__row > .app-content__col {
    flex-direction: row;
    width: calc(50% - 12px);
    margin: 0;
  }
}
@media (min-width: 1064px) {
  .app-content__row + .app-content__row {
    margin-top: 24px;
  }
}

.alert {
  margin: 0 0 25px 0;
  padding: 12px 15px;
  border-radius: 16px;
  background-color: #22e06c;
}
@media (min-width: 768px) {
  .alert {
    padding: 17px 20px;
  }
}
.alert *:last-child {
  margin-bottom: 0;
}

.alert--success {
  background-color: #dafd82;
}

.alert--info {
  background-color: #22e06c;
}

.alert--danger {
  background-color: #ffc4c4;
}

.campaign {
  display: flex;
  flex-direction: column;
  padding: 12px 0 12px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
@media (min-width: 576px) {
  .campaign {
    flex-direction: row;
  }
}
.campaign + .campaign {
  border-top: 1px solid #f0f0f0;
}
.campaign .icon {
  color: #123309;
}

.campaign--on {
  display: inline-flex;
  margin-top: 6px;
  padding: 8px 30px 8px 12px;
  font-weight: 700;
  border-radius: 12px;
  background: #dafd82;
}
.campaign--on + .campaign--on {
  border: none;
}

.campaign--status {
  font-size: 14px;
}

.campaign__ico {
  flex: 0 0 auto;
}
@media (min-width: 576px) {
  .campaign__ico {
    margin-right: 12px;
  }
}
.campaign__ico img,
.campaign__ico .icon {
  width: 28px;
}

.campaign__title {
  flex: 1 1 auto;
  margin-top: 4px;
  margin-right: 12px;
}

.campaign__status {
  flex: 0 0 auto;
  width: 100%;
  height: 24px;
  margin-top: 4px;
  font-size: 14px;
  border-radius: 12px;
  background-color: #f0f0f0;
}
@media (min-width: 576px) {
  .campaign__status {
    width: 150px;
  }
}
.campaign__status em {
  display: inline-block;
  width: 100%;
  height: 24px;
  padding: 5px 10px;
  font-style: normal;
  line-height: 1;
  border-radius: 12px;
}
.campaign__status.is-partial em {
  text-align: right;
  background-color: #ffda44;
}
.campaign__status.is-full em {
  text-align: right;
  background-color: #22e06c;
}

.country {
  position: relative;
  width: 100%;
  margin-top: 8px;
  background: url("../img/select.svg") right 12px center no-repeat;
}
.country.is-open .country__switch {
  display: block;
}

.country__select {
  display: none;
}

.country__selected {
  width: 100%;
  max-width: 100%;
  padding: 12px 12px 12px 44px;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #555555;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: left 12px center;
  cursor: default;
}

.country__switch {
  position: absolute;
  top: 47px;
  right: 0;
  left: 0;
  z-index: 11;
  display: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #555555;
  border-top: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #fff;
  cursor: default;
}
.country__switch li {
  margin: 0;
  padding: 12px 12px 12px 44px;
  background-repeat: no-repeat;
  background-position: left 12px center;
}
.country__switch li:hover, .country__switch li:focus {
  background-color: #f0f0f0;
}

.country__selected[data-lang=cs],
.country__switch li[data-lang=cs] {
  background-image: url("../img/flags/cs.png");
}
.country__selected[data-lang=sk],
.country__switch li[data-lang=sk] {
  background-image: url("../img/flags/sk.png");
}
.country__selected[data-lang=hu],
.country__switch li[data-lang=hu] {
  background-image: url("../img/flags/hu.png");
}
.country__selected[data-lang=pl],
.country__switch li[data-lang=pl] {
  background-image: url("../img/flags/pl.png");
}
.country__selected[data-lang=ro],
.country__switch li[data-lang=ro] {
  background-image: url("../img/flags/ro.png");
}

.graph {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
@media (min-width: 1064px) {
  .graph {
    flex-direction: row;
    justify-content: space-between;
  }
}

.graph__legend em {
  margin-right: 12px;
  font-style: normal;
  white-space: nowrap;
}

.graph__point {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 2px 4px 0 0;
  vertical-align: top;
  border-radius: 50%;
  background-color: #22e06c;
}
.graph__point.graph__point--1 {
  background-color: #2768b5;
}
.graph__point.graph__point--2 {
  background-color: #e4b200;
}
.graph__point.graph__point--3 {
  background-color: #d455cf;
}
.graph__point.graph__point--4 {
  background-color: #12b3f8;
}
.graph__point.graph__point--5 {
  background-color: #00acb7;
}
.graph__point.graph__point--6 {
  background-color: #ed7200;
}
.graph__point.graph__point--7 {
  background-color: #8053c9;
}
.graph__point.graph__point--8 {
  background-color: #f889d2;
}

.graph__controls {
  flex: 0 0 auto;
  margin-top: 12px;
}
@media (min-width: 1064px) {
  .graph__controls {
    margin-top: 0;
  }
}
.graph__controls a {
  display: inline-block;
  margin-left: 5px;
}
.graph__controls a:hover .icon, .graph__controls a:focus .icon {
  color: #000;
}
.graph__controls .icon {
  width: 22px;
  color: #555555;
  transition: all 0.3s;
}

.export {
  display: inline-block;
  margin: 0 2px;
  padding: 2px 10px 1px 10px;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #555555;
  border-radius: 4px;
}
.export:hover, .export:focus {
  background-color: #fff;
}

.file {
  display: flex;
  align-items: center;
}

.file__image {
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
  justify-content: center;
  min-width: 35px;
  min-height: 35px;
  background-color: #fff;
}
@media (min-width: 1064px) {
  .file__image {
    min-width: 80px;
    min-height: 80px;
  }
}
.file__image img {
  width: auto;
  max-width: 30px;
  max-height: 30px;
}
@media (min-width: 1064px) {
  .file__image img {
    max-width: 250px;
    max-height: 70px;
  }
}

.file__name {
  position: relative;
  flex: 1 1 auto;
  padding: 0 16px;
}
.file__name:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  left: 24px;
  z-index: 10;
  display: block;
  height: 1px;
  border-top: 1px dashed #a9a9a9;
}
.file__name em {
  position: relative;
  z-index: 11;
  display: inline-block;
  padding-right: 12px;
  font-style: normal;
  background-color: #f3f3f3;
}

.file__delete {
  flex: 0 0 auto;
  color: #555555;
}
.file__delete:hover, .file__delete:focus {
  color: #d30000;
}
.file__delete:hover .icon, .file__delete:focus .icon {
  color: #d30000;
}
.file__delete .icon {
  width: 24px;
  color: #555555;
}

.indicator {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media (min-width: 1320px) {
  .indicator {
    margin-top: -12px;
  }
}

.indicator__item {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 12px 0 0 0;
  padding: 40px 16px 24px 16px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-radius: 16px;
  background-color: #f0f0f0;
}
@media (min-width: 576px) {
  .indicator__item {
    width: calc(50% - 6px);
  }
}
@media (min-width: 1320px) {
  .indicator__item {
    width: calc(25% - 12px);
    margin-top: 24px;
  }
}
.indicator__item .icon {
  width: 32px;
  margin-bottom: 16px;
  color: #555555;
}
.indicator__item .icon--indicator {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
}
.indicator__item.is-success {
  background-color: #f4feda;
}
.indicator__item.is-success .icon {
  color: #22e06c;
}
.indicator__item.is-danger {
  background-color: #ffeded;
}
.indicator__item.is-danger .icon {
  color: #d30000;
}

.info {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 16px 0;
  padding: 14px;
  line-height: 1.5;
  border-radius: 16px;
  background: #fff;
}
@media (min-width: 1064px) {
  .info {
    margin: 0;
    padding: 24px;
  }
}
.info .pack {
  flex: 0 0 auto;
  margin-right: 8px;
}
@media (min-width: 1064px) {
  .info .pack {
    margin-right: 16px;
  }
}
.info.info--1 {
  background-color: #e9f0f7;
}
.info.info--2 {
  background-color: #fcf7e5;
}
.info.info--3 {
  background-color: #f5edff;
}
.info.info--4 {
  background-color: #e7f7fe;
}
.info.info--5 {
  background-color: #e5f7f8;
}
.info.info--6 {
  background-color: #fdf1e6;
}
.info.info--7 {
  background-color: #f2edf9;
}
.info.info--8 {
  background-color: #fddff2;
}

.info__title {
  flex: 1 1 auto;
  margin-right: 8px;
  font-weight: 600;
}
@media (min-width: 1064px) {
  .info__title {
    margin-right: 16px;
    font-size: 18px;
  }
}

.info__value {
  font-weight: 700;
  white-space: nowrap;
}
@media (min-width: 1064px) {
  .info__value {
    font-size: 20px;
  }
}
.info__value.is-negative {
  color: #d30000;
}

.item {
  display: flex;
  flex-direction: column;
  padding: 12px 14px;
  color: #123309;
  line-height: 1.5;
  border-radius: 16px;
  background: #fff;
}
@media (min-width: 1064px) {
  .item {
    flex-direction: row;
    padding: 16px 24px;
  }
}
.item + .item {
  margin-top: 16px;
}
.item .icon {
  color: #123309;
}

.item--success {
  color: #123309;
}
.item--success .icon {
  color: #22e06c;
}

.item--danger {
  color: #d30000;
}
.item--danger .icon {
  color: #d30000;
}

.item__ico {
  flex: 0 0 auto;
}
@media (min-width: 1064px) {
  .item__ico {
    margin-right: 16px;
  }
}
.item__ico img,
.item__ico .icon {
  width: 42px;
}

.item__title {
  flex: 1 1 auto;
  margin: 10px 0 0 0;
  font-size: 18px;
  font-weight: 500;
}
@media (min-width: 1064px) {
  .item__title {
    margin-right: 16px;
  }
}

.item__control {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 12px;
}
@media (min-width: 400px) {
  .item__control {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 1064px) {
  .item__control {
    margin-top: 0;
  }
}
.item__control a:not(.btn) {
  margin-top: 12px;
}
.item__control a:not(.btn):hover, .item__control a:not(.btn):focus {
  opacity: 0.6;
}
@media (max-width: 575px) {
  .item__control a:not(.btn) {
    width: 50%;
  }
}
.item__control a + * {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .item__control a + * {
    margin-bottom: 0;
    margin-left: 16px;
  }
}
.item__control .icon {
  width: 16px;
  color: #000;
}

.login {
  font-size: 14px;
  text-align: center;
}
.login label {
  font-size: 16px;
  text-align: left;
}
.login .container {
  max-width: 516px;
}
.login .title {
  color: #fff;
}
@media (min-width: 1064px) {
  .login .title {
    margin-bottom: 25px;
    font-size: 32px;
    font-weight: 800;
  }
}
@media (min-width: 1064px) {
  .login .box {
    margin-bottom: 60px;
    padding: 56px 56px 30px 56px;
  }
}
.login .btn {
  min-width: 200px;
}

.login__links {
  padding: 15px 0;
}
.login__links a {
  color: #123309;
}
.login__links a:hover, .login__links a:focus {
  text-decoration: underline;
}

.login__separator {
  position: relative;
  margin-top: 30px;
  padding-bottom: 20px;
  border-top: 1px solid #555555;
}
.login__separator em {
  position: absolute;
  left: calc(25% - 10px);
  display: inline-block;
  margin-top: -20px;
  padding: 10px;
  font-style: normal;
  background: #fff;
}
@media (min-width: 400px) {
  .login__separator em {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 1064px) {
  .login__separator em {
    padding: 10px 26px;
  }
}

.login__external {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 36px;
  border: 1px solid #555555;
  border-radius: 24px;
  background: #fff;
}
.login__external:hover, .login__external:focus {
  background-color: #f0f0f0;
}
.login__external img {
  position: absolute;
  top: 12px;
  left: 12px;
}

.metrics {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media (min-width: 1064px) {
  .metrics {
    margin-top: 12px;
  }
}
.metrics li {
  position: relative;
  flex-grow: 0;
  width: 100%;
  margin: 12px 0;
  padding: 24px 16px;
  font-size: 20px;
  text-align: center;
  border-radius: 16px;
  background: #f4ffda;
}
@media (min-width: 1320px) {
  .metrics li {
    width: calc(33.3333333333% - 12px);
  }
}
.metrics li:before {
  content: "";
  position: absolute;
  top: -32px;
  left: 50%;
  display: block;
  width: 24px;
  height: 40px;
  margin-left: -20px;
  background: url("../img/connector-metrics.svg") center center no-repeat;
  transform: rotate(90deg);
}
@media (min-width: 1064px) {
  .metrics li:before {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
.metrics li:first-child:before {
  display: none;
}
.metrics li strong {
  display: block;
  padding-bottom: 12px;
}
@media (min-width: 1320px) {
  .metrics li:nth-child(4), .metrics li:nth-child(5) {
    width: calc(50% - 12px);
  }
  .metrics li:nth-child(4):before {
    top: -32px;
    left: 150px;
    transform: rotate(90deg);
  }
  .metrics li:nth-child(5):before {
    left: -4px;
  }
}

.register .container {
  max-width: 862px;
}
.register .title {
  color: #fff;
}
@media (min-width: 1064px) {
  .register .title {
    margin-bottom: 25px;
    font-size: 32px;
    font-weight: 800;
  }
}
@media (min-width: 1320px) {
  .register .box {
    padding: 56px;
  }
}
.register .faq {
  border-top: 1px solid #adadad;
}
@media (min-width: 1064px) {
  .register .how {
    max-width: 862px;
  }
}

.register__submit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.register__submit .btn:hover, .register__submit .btn:focus {
  color: #000;
  border-color: #fff;
  background-color: #fff;
}

.register__back a {
  display: block;
  padding: 2px 0 2px 28px;
  color: #dafd82;
  font-weight: 700;
  background: url("../img/register-back.svg") 2px center no-repeat;
}
.register__back a:hover, .register__back a:focus {
  background-position: left center;
}

.pack {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: #22e06c;
}
@media (min-width: 1064px) {
  .pack {
    width: 40px;
    height: 40px;
  }
}
.pack .icon {
  width: 16px;
  color: #fff;
}
@media (min-width: 1064px) {
  .pack .icon {
    width: 24px;
  }
}
.pack.pack--1 {
  background-color: #2768b5;
}
.pack.pack--2 {
  background-color: #e4b200;
}
.pack.pack--3 {
  background-color: #d455cf;
}
.pack.pack--4 {
  background-color: #12b3f8;
}
.pack.pack--5 {
  background-color: #00acb7;
}
.pack.pack--6 {
  background-color: #ed7200;
}
.pack.pack--7 {
  background-color: #8053c9;
}
.pack.pack--8 {
  background-color: #f889d2;
}

.preview {
  display: none;
}
.preview.is-open {
  display: block;
}

.preview__switch {
  display: flex;
  overflow: auto;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0 0 15px 0;
  list-style-type: none;
  font-size: 14px;
}
@media (min-width: 768px) {
  .preview__switch {
    overflow: visible;
    padding: 0;
  }
}
.preview__switch li {
  flex: 0 0 auto;
  width: 130px;
  margin: 0;
  padding: 0 10px;
  text-align: center;
  cursor: pointer;
}
@media (min-width: 768px) {
  .preview__switch li {
    width: 20%;
    padding: 0;
  }
}
.preview__switch li img {
  margin: 0 auto 8px auto;
}
.preview__switch li.is-active {
  font-weight: 700;
}
.preview__switch li.is-active em {
  border-bottom: 2px solid #000;
}
.preview__switch em {
  display: inline-block;
  padding-bottom: 2px;
  font-style: normal;
}

.preview__detail {
  display: none;
  margin: 20px 0;
}
@media (min-width: 1064px) {
  .preview__detail {
    margin: 40px 0 12px 0;
  }
}
.preview__detail.is-open {
  display: block;
}

.progress {
  margin: 5px 0;
  line-height: 1;
}
@media (min-width: 1064px) {
  .progress {
    margin: 10px 0 5px 0;
  }
}
.progress ul,
.progress li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.progress ul {
  display: flex;
  flex-wrap: wrap;
  max-width: 846px;
  margin: 0 auto;
}
@media (min-width: 1064px) {
  .progress ul {
    flex-wrap: nowrap;
    max-width: 100%;
    margin: 0 -8px;
  }
}
.progress li {
  flex: 1 1 auto;
  width: 100%;
  margin: 2px 0;
  padding: 12px 12px;
  border-radius: 12px;
  background-color: #22e06c;
}
@media (min-width: 1064px) {
  .progress li {
    width: auto;
    margin: 0 8px;
    text-align: center;
  }
}
.progress li.is-active {
  background-color: #fff;
}
.progress li.is-checked strong {
  overflow: hidden;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  text-indent: 9999px;
  background: url("../img/progress-check.svg") center center no-repeat;
}
@media (min-width: 1064px) {
  .progress li + li {
    position: relative;
  }
  .progress li + li:before {
    content: "";
    position: absolute;
    top: 8px;
    left: -16px;
    display: block;
    width: 16px;
    height: 32px;
    background: url("../img/progress.svg") center center no-repeat;
  }
}
.progress strong {
  display: inline-block;
  margin-right: 4px;
}
.progress .container {
  max-width: 1116px;
}

.progress--app {
  margin-top: 24px;
  margin-bottom: 24px;
}
.progress--app li.is-active {
  background-color: #ffda44;
}
.progress--app li.is-active:before {
  background: url("../img/progress-active.svg") center center no-repeat;
}
.progress--app li.is-inactive {
  background-color: #f0f0f0;
}
.progress--app li.is-inactive:before {
  background: url("../img/progress-inactive.svg") center center no-repeat;
}

.range input {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  appearance: none;
}
.range input::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  border: 0;
  border-radius: 12px;
  background: linear-gradient(90deg, #22e06c 0%, #dcdcdc 100%);
  cursor: pointer;
}
.range input::-webkit-slider-thumb {
  width: 24px;
  height: 24px;
  margin-top: -6px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #22e06c;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.06), 0 3px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  appearance: none;
}
.range input:focus::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #22e06c 0%, #dcdcdc 100%);
}
.range input::-moz-range-track {
  width: 100%;
  height: 12px;
  border-radius: 12px;
  background: linear-gradient(90deg, #22e06c 0%, #dcdcdc 100%);
  cursor: pointer;
}
.range input::-moz-range-thumb {
  width: 24px;
  height: 24px;
  margin-top: -6px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #22e06c;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.06), 0 3px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  appearance: none;
}

.range__values {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

@media (max-width: 1063px) {
  .select-main {
    position: absolute;
    right: 8px;
    bottom: 18px;
    left: 8px;
  }
}
.select-main select {
  margin: -4px 0;
  padding: 12px 48px 12px 16px;
  line-height: 1;
  border: none;
  border-radius: 40px;
  background: #fff url("../img/select.svg") right 12px center no-repeat;
  cursor: pointer;
  appearance: none;
}
@media (min-width: 1064px) {
  .select-main select {
    max-width: 250px;
  }
}
@media (min-width: 1320px) {
  .select-main select {
    max-width: 350px;
  }
}

.settings {
  display: none;
}
.settings.is-open {
  display: block;
}

.settings__item {
  position: relative;
  margin-bottom: 12px;
  padding: 16px;
  border-radius: 16px;
  background-color: #f3f3f3;
}
@media (min-width: 1064px) {
  .settings__item {
    padding: 16px 32px;
  }
}
.settings__item:before {
  content: "";
  position: absolute;
  top: 16px;
  right: 10px;
  display: block;
  width: 24px;
  height: 24px;
  background: url("../img/faq.svg") center center no-repeat;
  transition: all 0.4s;
  pointer-events: none;
}
@media (min-width: 1064px) {
  .settings__item:before {
    top: 30px;
    right: 32px;
  }
}
.settings__item.is-open:before {
  transform: rotate(180deg);
}
.settings__item.is-open .settings__content {
  display: block;
}

.settings__title {
  margin: -16px;
  padding: 16px 30px 16px 16px;
  color: #000;
  font-weight: 700;
  cursor: pointer;
}
@media (min-width: 1064px) {
  .settings__title {
    margin: -16px -32px;
    padding: 16px 60px 16px 32px;
  }
}
.settings__title small {
  display: block;
  color: #d30000;
  font-size: 14px;
  font-weight: 400;
}

h2.settings__title,
h3.settings__title,
h4.settings__title {
  margin-bottom: -16px;
  font-size: 18px;
}

.settings__content {
  display: none;
  padding: 16px 0 0 0;
}
@media (min-width: 1064px) {
  .settings__content {
    padding: 16px 16px 0 16px;
  }
}

.settings__note {
  color: #555555;
}
.settings__note p,
.settings__note ul,
.settings__note ol {
  margin-bottom: 0;
}
.settings__note ul {
  padding-left: 18px;
}

.user-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.user-list li {
  margin: 0;
  padding: 0;
}
.user-list li + li {
  margin-top: 5px;
}
.user-list a {
  color: #d30000;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  vertical-align: middle;
}
.user-list a:hover, .user-list a:focus {
  color: #22e06c;
}
.app-content .box .user-list a {
  text-decoration: none;
}

.tabs {
  margin-bottom: 40px;
  text-align: center;
}
.tabs ul,
.tabs li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.tabs ul {
  display: inline-block;
  font-size: 18px;
  border-radius: 25px;
  background-color: #f3f3f3;
}
@media (min-width: 576px) {
  .tabs ul {
    border-radius: 40px;
  }
}
.tabs li {
  display: block;
  padding: 16px 32px;
  cursor: pointer;
}
@media (min-width: 576px) {
  .tabs li {
    display: inline-block;
  }
}
.tabs li.is-active {
  font-weight: 700;
  border-radius: 40px;
  background-color: #22e06c;
}