.not_found_container {
    padding-top: 84px;
    display: flex;
    min-height: 60vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.not_found_container svg {
    max-width: 70%;
    height: auto;
    /* zachová proporce */
    margin: 0 auto;
}

.heading {
    color: #DBFD83;
}

.text {
    color: white;
    font-size: 18px;
    margin-bottom: 0 !important;
}

.anchor {
    font-weight: 600;
    color: #DBFD83;
    border-bottom: 1px solid #DBFD83;
    transition: all 200ms linear;
}

.anchor:hover {
    border-bottom: 1px solid transparent;
}