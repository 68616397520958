.twoFactorBox {
    max-width: 60% !important;
    justify-self: center;
}

@media (max-width: 1064px) {
    .twoFactorBox {
        max-width: 90% !important;
    }
}

h2 {
    margin-bottom: 1rem !important;
}

p {
    margin: 0;
    margin-bottom: 1rem;
}

