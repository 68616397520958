.progressContainer {
    /* position: absolute; */
    /* bottom: -100px;
    left: 25%; */
    /* margin-top: 12px; */
}

.title {
    padding-top: 12px;
    margin: 0 0 4px;
    font-weight: 600;
    color: black;
    text-align: center;
    border-top: 3px dashed #123309;
}

.status {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 100vh;
    font-size: 0.875rem;
    height: 34px;
    width: 90%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 1rem;
}

@media (min-width: 768px) {
    .progressContainer {
        display: none;
    }
}

.status em {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    font-style: normal;
    white-space: nowrap;
    color: #000;
    font-weight: 600;
}

.progress__fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 100vh;
    width: calc(var(--value) / 30 * 100%);
    transition: width 0.3s ease, background-color 0.3s ease;
    z-index: 1;
}