.dialog_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: zoom-out;
}

.enlarged_image {
    max-width: 95vw;
    max-height: 100vh;
    object-fit: contain;
    cursor: default;
}

/* Stylování křížku pro zavření modalu */
.close_button {
    position: absolute;
    top: 20px;
    right: 40px;
    background: none;
    border: none;
    font-size: 32px;
    color: white;
    cursor: pointer;
    z-index: 1002; /* Musí být vyšší než modal-overlay */
    transition: all 250ms linear;
}

.close_button:hover {
    color: #bdbdbd;
}

